import './Hero.scss';
import HeroTigers from './HeroTigers';
import HeroTigrilla from './HeroTigrilla';
import HeroEpisode from './HeroEpisode';
import { useEffect, useState } from 'react';

function Hero() {
  const [tiger, setTiger] = useState('T1');
  const handleTiger = (id) => {
    setTiger(id);
  };
  return (
    <div className="Hero">
      <HeroTigers tiger={tiger} handleTiger={handleTiger} />{' '}
      <HeroTigrilla tiger={tiger} />
      <HeroEpisode />
    </div>
  );
}

export default Hero;
