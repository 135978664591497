// AudioProvider.js
import React, { useState, useEffect } from 'react';
import AudioContext from './AudioContext';

const AudioProvider = ({ children, musicFile }) => {
  const [audio] = useState(new Audio(musicFile));
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    isPlaying ? audio.play() : audio.pause();
  }, [isPlaying, audio]);

  useEffect(() => {
    audio.muted = isMuted;
  }, [isMuted, audio]);

  useEffect(() => {
    audio.loop = true;
  }, [audio]);

  return (
    <AudioContext.Provider
      value={{ isPlaying, setIsPlaying, isMuted, setIsMuted }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export default AudioProvider;
