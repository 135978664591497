import { useState } from 'react';
import { Link } from 'react-router-dom';
import './CartList.scss';
import CartProduct from './CartProduct';
import ukraine from './img/ukraine.svg';
import worldwide from './img/worldwide.svg';

function CartList({ handleCart, series, cart, cartSumm }) {
  return (
    <div className="CartList">
      <div className="CartList__list">
        {cart
          ? cart.map((item) => {
              const ep = series.find((seria) => {
                return seria.episode === item.itemId;
              });
              return (
                <CartProduct
                  key={ep.episode}
                  id={ep.episode}
                  img={ep.merchImg}
                  name={ep.merchName}
                  price={ep.merchPrice}
                  className="CartProduct_cart"
                  quant={item.count}
                  maxQuant={ep.merchQuant}
                  handleCart={handleCart}
                />
              );
            })
          : ''}
      </div>

      <div className="CartList__buy">
        <div className="CartList__buy__heading">
          <h1>Subtotal:</h1>
          <h1>$ {cartSumm}</h1>
        </div>
        <Link to="/delivery">BUY</Link>
        <div className="CartList__buy__charity">
          <img src={ukraine} />
          <p>10% of proceeds go to charity</p>
        </div>
        <div className="CartList__buy__delivery">
          <img src={worldwide} />
          <p>Worldwide shipping</p>
        </div>
      </div>
    </div>
  );
}

export default CartList;
