import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import Card from './Card';
import Arrow from '../../Arrow/Arrow';
import './Carousel.scss';
import series from '../../../data/series';
import { useRef, useState } from 'react';

function Carousel({ title, className, link, episodeId }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef();
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex);
  };
  return (
    <div className={`Carousel ${className}`}>
      <div className="Carousel__Heading">
        <h3>{title}</h3>
        <Link to={`/${link}`}>More &#8250;</Link>
      </div>
      <Swiper
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={handleSlideChange}
        spaceBetween={20}
        breakpoints={{
          300: {
            slidesPerView: 1,
          },
          500: {
            slidesPerView: 2,
            //spaceBetween: 40,
          },
          1000: {
            slidesPerView: 3,
            //spaceBetween: 50,
          },
          1300: {
            slidesPerView: 4,
            //spaceBetween: 50,
          },
          1900: {
            slidesPerView: 5,
            //spaceBetween: 50,
          },
        }}>
        {series.map((seria) => (
          <SwiperSlide key={seria.episode}>
            <Card
              num={seria.episode}
              image={link === 'merch' ? seria.merchImg : seria.cover}
              date={seria.date}
              episodeId={episodeId}
              link={link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {currentSlide > 0 && (
        <Arrow
          className="Arrow__left Arrow"
          dirct="left"
          onClick={() => swiperRef.current?.slidePrev()}
        />
      )}
      {currentSlide < 6 && (
        <Arrow
          className="Arrow__right Arrow"
          dirct="right"
          onClick={() => swiperRef.current?.slideNext()}
        />
      )}
    </div>
  );
}

export default Carousel;
