import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import t1 from './Tigers/img/T1.jpeg';
import t2 from './Tigers/img/T2.jpeg';
import t3 from './Tigers/img/T3.jpeg';
import t4 from './Tigers/img/T4.jpeg';
import t5 from './Tigers/img/T5.jpeg';
import t6 from './Tigers/img/T6.jpeg';
import t7 from './Tigers/img/T7.jpeg';
import t8 from './Tigers/img/T8.jpeg';
import Arrow from '../../Arrow/Arrow';
import { useRef, useState } from 'react';

const tigers = [t1, t2, t3, t4, t5, t6, t7, t8];

function HeroTigers({ tiger, handleTiger }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef();
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex);
  };
  return (
    <div className="Hero__tigers">
      <h3>TIGRILLA NFT:</h3>
      <div className="Hero__tigers__gallery desktop">
        {tigers.map((tigerImg, index) => (
          <img
            key={index}
            src={tigerImg}
            className={tiger === `T${index + 1}` ? 'active' : ''}
            onClick={() => handleTiger(`T${index + 1}`)}
            alt={`tiger__${index + 1}`}
          />
        ))}
      </div>
      <div className="Hero__tigers__gallery mobile">
        <Swiper
          className="mySwiper"
          slidesPerView="auto"
          spaceBetween={20}
          modules={[Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={handleSlideChange}>
          {tigers.map((tigerImg, index) => (
            <SwiperSlide key={index}>
              <img
                src={tigerImg}
                className={tiger === `T${index + 1}` ? 'active' : ''}
                onClick={() => handleTiger(`T${index + 1}`)}
                alt={`tiger__${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {currentSlide > 0 && (
          <Arrow
            className="Arrow__left Arrow"
            dirct="left"
            onClick={() => swiperRef.current?.slidePrev()}
          />
        )}
        {currentSlide < 8 && (
          <Arrow
            className="Arrow__right Arrow"
            dirct="right"
            onClick={() => swiperRef.current?.slideNext()}
          />
        )}
      </div>
    </div>
  );
}

export default HeroTigers;
