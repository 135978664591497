// MuteButton.jsx
import React, { useContext } from 'react';
import AudioContext from './AudioContext';
import equalizer from './eq.gif'; // Импортируйте активный эквалайзер
import equalizerMuted from './eqm.gif'; // Импортируйте отключенный эквалайзер

const MuteButton = ({ onToggleMute }) => {
  const { isMuted, setIsMuted } = useContext(AudioContext);

  return (
    <button
      onClick={() => {
        setIsMuted(!isMuted);
      }}
      style={{ background: 'none', border: 'none', cursor: 'pointer' }}
    >
      <img
        src={isMuted ? equalizerMuted : equalizer}
        alt={isMuted ? 'Включить звук' : 'Выключить звук'}
        style={{ width: '24px', height: '24px' }}
      />
    </button>
  );
};

export default MuteButton;
