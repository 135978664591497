import Collapsible from 'react-collapsible';
import './Tasks.scss';
import bg from './img/bg.png';

function Tasks() {
  return (
    <section className="Tasks">
      <div className="Tasks__top">
        <div className="Tasks__top__left" style={{ background: `url(${bg})` }}>
          <h5>Timer</h5>
        </div>
        <div className="Tasks__top__right">
          <div className="Tasks__top__right__goal">
            <h6>Charity goal</h6>
          </div>
          <div className="Tasks__top__right__stake">
            <h6>
              Stake: [Tokens icons] <br />
              More Stake - More Charity
            </h6>
          </div>
          <div className="Tasks__top__right__nft">
            <h6>
              Every participant <br />
              Get NFT for Metaverse
            </h6>
          </div>
          <div className="Tasks__top__right__rewards">
            <h6>
              Complete quests <br />
              win rewards
            </h6>
          </div>
          <div className="Tasks__top__right__price">
            <h6>
              xx xxx$ <br />
              Prize fund
            </h6>
          </div>
        </div>
      </div>
      <div className="Tasks__bottom">
        {/* <div className="Tasks__bottom__buttons">
          <button className="buttonStaking">Staking</button>
          <button className="buttonQuests">Quests</button>
        </div> */}

        <Collapsible trigger={<h4>Staking</h4>}>
          <ul className="Staking">
            <li>Token</li>
            <li>Staking platform</li>
            <li>link</li>
            <li>Staking goal</li>
            <li>Current charity lvl</li>
            <li>Next charity lvl</li>
            <li>APT</li>
            <li>OKX</li>
            <li>
              <button>BUTTON</button>
            </li>
            <li>100k/120k</li>
            <li>10 000 USDT</li>
            <li>20 000 USDT</li>
          </ul>
        </Collapsible>

        <Collapsible trigger={<h4>Quests</h4>}>
          <ul className="Quests">
            <li>Partner</li>
            <li>Action</li>
            <li>Prize fund</li>
            <li>Conditions</li>
            <li>Participate</li>
            <li>Aptos</li>
            <li>Social activity</li>
            <li>10000 APT</li>
            <li>
              <button>BUTTON</button>
            </li>
            <li>
              <button>BUTTON</button>
            </li>
          </ul>
        </Collapsible>
      </div>
    </section>
  );
}

export default Tasks;
