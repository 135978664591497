import { Routes, Route, Outlet, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import MuteButton from '../BackgroundMusic/MuteButton';
import Burger from './Burger';
import './Header.scss';
import logo from './img/logo__desktop.svg';

function Header({ cart }) {
  const [burger, setBurger] = useState(false);
  const checkBurger = () => {
    if (!burger) {
      setBurger(true);
    } else {
      setBurger(false);
    }
  };

  const totalCart = cart.reduce(
    (accumulator, item) => accumulator + item.count,
    0
  );

  const onlyWidth = useWindowWidth();
  useEffect(() => {
    if (onlyWidth > 1133) {
      setBurger(false);
    }
  });

  useEffect(() => {
    const cartQuantity = document.querySelector('.Header__menu__cart__q');
    if (cartQuantity) {
      cartQuantity.classList.add('pulsate');

      const animationTimeout = setTimeout(() => {
        cartQuantity.classList.remove('pulsate');
      }, 1000);

      return () => clearTimeout(animationTimeout);
    }
  }, [cart]);

  return (
    <>
      <header className={burger ? 'Header Header_black' : 'Header'}>
        <Link
          className="Header__logo"
          to="/"
          onClick={burger ? checkBurger : null}>
          <img src={logo} />
        </Link>

        <nav className="Header__menu">
          <ul className="Header__menu__list">
            <li>
              <MuteButton /> {/* Добавьте MuteButton */}
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/series">Series</Link>
            </li>
            <li>
              <Link to="/merch">Merch</Link>
            </li>
            <li>
              <Link to="/tasks">Tasks</Link>
            </li>
            <li className="Header__menu__cart">
              <Link to={cart[0] ? '/cart' : null}>
                <svg
                  width="24"
                  height="24"
                  fill="white"
                  className="cart__icon"
                  viewBox="0 0 16 16">
                  <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                </svg>
              </Link>

              {cart[0] ? (
                <div className="Header__menu__cart__q">{totalCart}</div>
              ) : (
                ''
              )}
            </li>
          </ul>
          {/* <Fade direction="down" delay="50" triggerOnce>
              <Link to="/mint" className="button">
                MINT
              </Link>
            </Fade> */}
        </nav>
        <div className="Header__mobile">
          <MuteButton className="mute" />{' '}
          <div
            onClick={checkBurger}
            className={
              burger
                ? 'Header__mobile__burger__icon Header__mobile__burger__icon_active'
                : 'Header__mobile__burger__icon'
            }>
            <span></span>
          </div>
        </div>
      </header>

      <div className={burger ? 'Burger Burger_active' : 'Burger'}>
        <Burger checkBurger={checkBurger} cart={cart} />
      </div>
    </>
  );
}

export default Header;
