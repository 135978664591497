import React, { useEffect, useRef } from 'react';
import PlayButton from '../BackgroundMusic/PlayButton';
import logo from './img/logo__desktop.svg';
import './Enter.scss';

const videoSrc = require('./video/934761515826096736.mp4');
let videoCached = false;

function Enter({ handleEnter }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const cacheVideo = async () => {
      if (!videoCached) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', videoSrc, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            const blob = xhr.response;
            const url = URL.createObjectURL(blob);
            videoCached = true;
            localStorage.setItem(`enter-video`, url);
            if (videoRef.current) {
              videoRef.current.src = url;
              videoRef.current.load();
            }
          }
        };
        xhr.send();
      } else {
        const url = localStorage.getItem(`enter-video`);
        if (videoRef.current && url) {
          videoRef.current.src = url;
          videoRef.current.load();
        }
      }
    };

    cacheVideo();
  }, []);

  return (
    <div className="Enter">
      <video ref={videoRef} autoPlay loop muted playsInline>
        <source type="video/mp4" />
      </video>
      <img src={logo} alt="logo" />
      <PlayButton handleEnter={handleEnter} />
    </div>
  );
}

export default Enter;
