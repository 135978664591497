import MerchHero from './MerchHero';
import MerchGallery from './MerchGallery';
import './Merch.scss';

function Merch({ series, epId, handleCart, episodeId, cart }) {
  return (
    <section className="Merch">
      <MerchHero
        series={series}
        handleCart={handleCart}
        epId={epId}
        cart={cart}
        episodeId={episodeId}
      />
      <MerchGallery series={series} episodeId={episodeId} />
    </section>
  );
}

export default Merch;
