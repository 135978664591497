import merch1 from './img/merch/merch1.png';
import merch2 from './img/merch/merch2.png';
import cover1 from './img/series/ep2.jpg';
import cover2 from './img/series/ep2.jpg';
import cover3 from './img/series/ep3.jpg';
import cover4 from './img/series/ep4.jpg';
import cover5 from './img/series/ep5.jpg';
import cover6 from './img/series/ep6.jpg';
import cover7 from './img/series/ep7.jpg';
import cover8 from './img/series/ep8.jpg';
import cover9 from './img/series/ep9.jpg';
import cover10 from './img/series/ep10.jpg';

const series = [
  {
    episode: 1,
    title: 'NFTiger',
    description:
      "The routine life of a Tattoo Master from Kiev was disrupted by a unexpectedly found artifact, a mysterious USB Drive. Coming home, he decides to find out what this strange data carrier hides, connects the flash drive to his laptop, and nothing happens. The protagonist didn't suspect that his actions had let something into our world...",
    date: 1668270456,
    cover: cover1,
    video: 'jytiYHBUuPs',
    merchName: 'USB Drive',
    merchPrice: 50,
    merchImg: merch1,
    merchDesc:
      "Boss Meganamus's flash drive was no ordinary storage device. It was a black finger capable of stealing the souls of NFTs. In the hands of the conqueror of the digital world, it held great power. Beware the black finger and its secret program.",
    merchStatus: 'hot item',
    merchQuant: 15,
  },
  {
    episode: 2,
    title: '2title',
    description: '2description',
    date: 16805856000,
    cover: cover2,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 3,
    title: '3title',
    description: '3description',
    date: '',
    cover: cover3,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 4,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover4,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 5,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover5,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 6,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover6,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 7,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover7,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 8,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover8,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 9,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover9,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
  {
    episode: 10,
    title: '2title',
    description: '2description',
    date: '',
    cover: cover10,
    video: '',
    merchName: '',
    merchPrice: 75,
    merchImg: '',
    merchDesc: 'merchmerchmerch2222',
    merchStatus: 'hot item',
    merchQuant: 5,
  },
];

export default series;
