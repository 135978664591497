import Collapsible from 'react-collapsible';
import './Roadmap.scss';
import video1 from './video/PUMP.mp4';
import video2 from './video/video2.mp4';
import video3 from './video/video_2023-03-20_21-34-53.mp4';

function Roadmap() {
  return (
    <section className="Roadmap">
      <div className="Roadmap__Heading">
        <h3>Roadmap:</h3>
      </div>
      <div className="Roadmap__Options">
        <Collapsible
          accordionPosition
          isOpen
          open
          trigger={
            <h2>
              <span>1</span>Stake for charity
            </h2>
          }>
          <ul>
            <li className="done">-Reach the charity goals;</li>
            <li>-Tigrilla series release;</li>
            <li>-Animal lovers involving;</li>
            <li>-NFT collection minting; </li>
            <li>-Partnerships with ecosystems.</li>
          </ul>
          <div className="gradient"></div>
          <video autoPlay loop muted playsInline>
            <source src={video1} type="video/mp4" />
          </video>
        </Collapsible>
        <Collapsible
          accordionPosition
          isOpen
          trigger={
            <h2>
              <span>2</span>Metaverse environment
            </h2>
          }>
          <ul>
            <li>-Spaces in metaverse for animal lovers;</li>
            <li>-New SfC campaigns;</li>
            <li>-Collaborations with Web3 gaming projects.</li>
          </ul>
          <div className="gradient"></div>
          <video autoPlay loop muted playsInline>
            <source src={video2} type="video/mp4" />
          </video>
        </Collapsible>
        <Collapsible
          accordionPosition
          isOpen
          trigger={
            <h2>
              <span>3</span>Metaverse gamification
            </h2>
          }>
          <ul>
            <li>-Games inside Tigrilla Mode Metaverse;</li>
            <li>-Regular SfC campaigns;</li>
            <li>-Regular collaborations with Web3 gaming projects.</li>
          </ul>
          <div className="gradient"></div>
          <video autoPlay loop muted playsInline>
            <source src={video3} type="video/mp4" />
          </video>
        </Collapsible>
      </div>
    </section>
  );
}

export default Roadmap;
