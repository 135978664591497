import './Series.scss';
import SeriesHero from './SeriesHero';
import Carousel from '../Main/Carousel/Carousel';

function Series({ series, epId, episodeId, handleCart, cart }) {
  return (
    <section className="Series">
      <SeriesHero
        series={series}
        handleCart={handleCart}
        epId={epId}
        cart={cart}
      />
      <Carousel
        className="Carousel_episode Carousel_episodeSeries"
        title="Episodes:"
        episodeId={episodeId}
        link="/series"
      />
    </section>
  );
}

export default Series;
