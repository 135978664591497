import './MerchCard.scss';
import secret from './img/secret.png';

function MerchCard({ num, image = '', title, status, price, episodeId, link }) {
  const merchStyle = {
    backgroundImage: `url(${image ? image : secret})`,
    cursor: 'pointer',
  };

  return (
    <div
      className={image ? 'MerchCard' : 'MerchCard_lock'}
      onClick={image ? () => episodeId(num) : undefined}
      to={link}
      style={{ ...merchStyle }}>
      <h5 className="MerchCard__num">
        <span>{num}</span>
      </h5>
      <h5 className="MerchCard__status">{status}</h5>
      <div className="MerchCard__info">
        <h5>{title}</h5>
        <p>$ {price}</p>
      </div>
    </div>
  );
}

export default MerchCard;
