import { Routes, Route, Outlet, Link } from 'react-router-dom';
import './Footer.scss';
import discord from './img/discord.png';
import telegram from './img/telegram.svg';
import instagram from './img/instagram.png';
import twitter from './img/twitter.png';
import youtube from './img/youtube.png';
import logo from './img/logo__desktop.svg';
import meganamus from './img/meganamus__logo.svg';
import metacops from './img/metacops__logo.svg';
import designer from './img/designer__logo.svg';

function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer__container">
        <div className="Footer__container__line">
          <span></span>
        </div>

        {/* <div className="Footer__container__menu">
        <ul className="Footer__container__menu__list">
          <li>
            <Link to="/series">Series</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/merch">Merch</Link>
          </li>
          <li>
            <Link to="/tasks">Tasks</Link>
          </li>
        </ul>
        <ul>
            <li>FAQs</li>
            <li>Terms and conditions</li>
            <li>Privacy</li>
            <li>License</li>
            <li>NFT Purchase agreement</li>
          </ul>
      </div> */}

        <div className="Footer__container__partners">
          <a href="#">
            <img src={logo} />
          </a>
          <a href="#">
            <img src={meganamus} />
          </a>
          <a href="#">
            <img src={metacops} />
          </a>
          <a href="#">
            <img src={designer} />
          </a>
        </div>

        <form className="Footer__container__subscribe">
          <p>Get in touch with our news</p>
          <input
            className="email"
            type="email"
            placeholder="Enter Your email"
          />
          <input className="submit" type="submit" value="Subscribe" />
        </form>

        <div className="Footer__container__copyrighting">
          2023 Copyright Tigrilla Mode
        </div>
        <div className="Footer__container__socials">
          <a href="#">
            <img src={youtube} />
          </a>
          <a target="_blank" href="https://t.me/tigrillamode">
            <img src={telegram} />
          </a>
          <a target="_blank" href="https://instagram.com/tigrillamode">
            <img src={instagram} />
          </a>
          <a target="_blank" href="https://twitter.com/tigrillamode">
            <img src={twitter} />
          </a>
          <a target="_blank" href="https://discord.gg/5UqsrMuy">
            <img src={discord} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
