import { Routes, Route, Outlet, Link } from 'react-router-dom';
import './Burger.scss';

function Burger({ checkBurger, cart }) {
  const totalCart = cart.reduce(
    (accumulator, item) => accumulator + item.count,
    0
  );
  return (
    <>
      <nav className="Burger__menu">
        <ul className="Burger__menu__list">
          <li>
            <Link to="/about" onClick={checkBurger}>
              About
            </Link>
          </li>
          <li>
            <Link to="/series" onClick={checkBurger}>
              Series
            </Link>
          </li>
          <li>
            <Link to="/merch" onClick={checkBurger}>
              Merch
            </Link>
          </li>
          <li>
            <Link to="/tasks" onClick={checkBurger}>
              Tasks
            </Link>
          </li>
          <li className="Burger__menu__cart">
            <Link to={cart[0] ? '/cart' : null} onClick={checkBurger}>
              <svg
                width="24"
                height="24"
                fill="white"
                className="cart__icon"
                viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
              </svg>
            </Link>
            {cart[0] ? (
              <div className="Burger__menu__cart__q">{totalCart}</div>
            ) : (
              ''
            )}
          </li>
        </ul>
        {/* <Link to="/mint" onClick={checkBurger} className="button">
          Mint
        </Link> */}
      </nav>
    </>
  );
}

export default Burger;
