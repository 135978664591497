import './Arrow.scss';
import leftArrow from './img/caret__left.svg';
import rightArrow from './img/caret__right.svg';

function Arrow({ dirct, onClick }) {
  return (
    <button className={`Arrow Arrow__${dirct}`} onClick={onClick}>
      <img src={dirct === 'left' ? leftArrow : rightArrow} />
    </button>
  );
}

export default Arrow;
