import { Routes, Route, Outlet, Link } from 'react-router-dom';

import series from '../../../data/series';
import YoutubeEmbed from '../../YoutubeEmbed/YoutubeEmbed';
import discord from './img/social_discord.png';
import telegram from './img/telegram.svg';
import instagram from './img/social_instagram.png';
import twitter from './img/social_twitter.png';
import youtube from './img/social_youtube.png';

function HeroEpisode() {
  const unixTime = Math.round(new Date().getTime() / 1000);
  const ep = series.find((s) => s.date <= unixTime);
  const {
    episode,
    title,
    description,
    video,
    merchName,
    merchImg,
    merchPrice,
  } = ep;
  return (
    <div className="Hero__episode">
      <div className="Hero__episode__title">
        <h3>
          EPISODE {episode}: {title}
        </h3>
        <Link to="/series">More &#8250;</Link>
      </div>

      <div className="Hero__episode__video">
        <YoutubeEmbed embedId={video} title="Episodelalala" />
      </div>

      <div className="Hero__episode__info">
        <h5 className="title">Episode info: </h5>
        <Link className="more" to="/series">
          More
        </Link>
        <div className="Hero__episode__info__description">
          <Link to="/series">
            {description.length > 200
              ? `${description.slice(0, description.lastIndexOf(' ', 200))}...`
              : description}
          </Link>
        </div>

        {/* <div className="Hero__episode__info__location">
          <img src={location} alt="" />
          <div className="Hero__episode__info__location__text">
            <h5>LOCATION:</h5>
            <p>KYIV, UKRAINE</p>
          </div>
        </div> */}

        <div className="Hero__episode__info__socials">
          <a href="#">
            {' '}
            <img src={youtube} />
          </a>
          <a target="_blank" href="https://t.me/tigrillamode">
            <img src={telegram} />
          </a>
          <a target="_blank" href="https://instagram.com/tigrillamode">
            <img src={instagram} />
          </a>
          <a target="_blank" href="https://twitter.com/tigrillamode">
            <img src={twitter} />
          </a>
          <a target="_blank" href="https://discord.gg/5UqsrMuy">
            <img src={discord} />
          </a>
        </div>
      </div>

      <div className="Hero__episode__stuff">
        <h5 className="title">Episode STUFF:</h5>
        <Link className="more" to="/merch">
          More
        </Link>
        <img src={merchImg} alt={merchName} />
        <h5 className="Hero__episode__stuff__name">{merchName}</h5>
      </div>
    </div>
  );
}
export default HeroEpisode;
