import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import Series from './components/Series/Series';
import Merch from './components/Merch/Merch';
import About from './components/About/About';
import Cart from './components/Cart/Cart';
import Tasks from './components/Tasks/Tasks';
import Delivery from './components/Cart/Delivery';
import { useEffect, useState } from 'react';
import seriez from './data/series';
import ScrollToTop from './tools/scrollToTop';
import Mint from './components/Header/Mint';
import AudioProvider from './components/BackgroundMusic/AudioProvider';
import Enter from './components/Main/Enter';
const music = require('./music/2222.mp3');

function App() {
  //enter begin
  const [enter, setEnter] = useState(false);
  const handleEnter = () => {
    setEnter(true);
    console.log('enter true');
  };
  //enter end

  const [series, setSeries] = useState(seriez);
  const [epId, setEpId] = useState(1);
  const [cart, setCart] = useState(
    () => JSON.parse(localStorage.getItem('cart')) || []
  );
  const [cartSumm, setCartSumm] = useState(0);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    setCartSumm(
      cart.reduce((sum, item) => {
        const mc = series.find((seria) => seria.episode === item.itemId);
        return sum + mc.merchPrice * item.count;
      }, 0)
    );
  }, [cart, series]);

  const handleEpisodeId = (id) => {
    setEpId(id);
  };

  const handleCart = (itemId, operator) => {
    const itemIndex = cart.findIndex((item) => item.itemId === itemId);
    if (itemIndex < 0) {
      setCart([...cart, { itemId, count: 1 }]);
    } else {
      const newCart = [...cart];
      switch (operator) {
        case '+':
          newCart[itemIndex] = {
            ...cart[itemIndex],
            count: cart[itemIndex].count + 1,
          };
          break;
        case '-':
          newCart[itemIndex] = {
            ...cart[itemIndex],
            count: cart[itemIndex].count - 1,
          };
          break;
        case 'x':
          newCart.splice(itemIndex, 1);
          break;
        default:
          break;
      }
      setCart(newCart);
    }
  };

  return (
    <AudioProvider musicFile={music}>
      <div className="App" id="App">
        {enter ? (
          <Router>
            <ScrollToTop />
            <Header cart={cart} />
            <Routes>
              <Route exact path="/mint" element={<Mint />} />
              <Route
                exact
                path="/cart"
                element={
                  <Cart
                    handleCart={handleCart}
                    series={series}
                    cart={cart}
                    cartSumm={cartSumm}
                  />
                }
              />
              <Route
                exact
                path="/delivery"
                element={
                  <Delivery series={series} cart={cart} cartSumm={cartSumm} />
                }
              />
              <Route exact path="/tasks" element={<Tasks />} />
              <Route
                exact
                path="/merch"
                element={
                  <Merch
                    handleCart={handleCart}
                    series={series}
                    epId={epId}
                    episodeId={handleEpisodeId}
                    cart={cart}
                  />
                }
              />
              <Route exact path="/about" element={<About />} />
              <Route
                exact
                path="/series"
                element={
                  <Series
                    handleCart={handleCart}
                    series={series}
                    cart={cart}
                    epId={epId}
                    episodeId={handleEpisodeId}
                  />
                }
              />
              <Route
                exact
                path="/"
                element={<Main episodeId={handleEpisodeId} />}
              />
            </Routes>
            <Footer />
          </Router>
        ) : (
          <Enter handleEnter={handleEnter} />
        )}
      </div>
    </AudioProvider>
  );
}

export default App;
