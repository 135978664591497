import './Main.scss';
import Hero from './Hero/Hero';
import Carousel from './Carousel/Carousel';

import Roadmap from './Roadmap/Roadmap';

function Main({ episodeId }) {
  return (
    <section className="Main">
      <Hero />

      <div className="Main__description">
        <p>
          Tigrilla MODE is a metaverse where animal lovers can make the world
          better together!
        </p>
        <p>
          EARN Crypto and nft for staking or completing quests during our series
          release. More stake - more charity from our partners
        </p>
        <p>This is the first chapter of our metaverse for animal lovers.</p>
      </div>

      <Carousel
        className="Carousel Carousel_episode"
        title="Episodes:"
        link="series"
        episodeId={episodeId}
      />

      <div className="Main__description">
        <p>
          Stake tokens or complete quests during series release earn crypto
          rewards, unique nft, merch, etc. This is the first chapter of the
          tigrilla mode metaverse.
        </p>
      </div>

      <Carousel
        className="Carousel Carousel_merch"
        title="Merch:"
        link="merch"
        episodeId={episodeId}
      />

      <Roadmap />
    </section>
  );
}

export default Main;
