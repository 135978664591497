import './SeriesHero.scss';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';

function SeriesHero({ series, handleCart, epId, cart }) {
  const ep = series.find((seria) => {
    return seria.episode === epId;
  });
  const {
    episode,
    title,
    description,
    video,
    merchName,
    merchPrice,
    merchImg,
    merchQuant,
  } = ep;

  const cartItem = cart.find((item) => item.itemId === episode);
  const itemCount = cartItem ? cartItem.count : 0;

  return (
    <section className="SeriesHero">
      <div className="SeriesHero__info">
        <h1>{title}</h1>
        <h5>
          <span>{episode}</span>episode
        </h5>
        <p className="SeriesHero__info__description">{description}</p>
        <div className="SeriesHero__info__stuff">
          <img src={merchImg} />
          <div className="stuff__info">
            <h6>{merchName}</h6>
            <span>$ {merchPrice}</span>
          </div>
          {itemCount && itemCount === merchQuant ? (
            <button disabled onClick={() => handleCart(episode, '+')}>
              BUY
            </button>
          ) : (
            <button onClick={() => handleCart(episode, '+')}>BUY</button>
          )}
        </div>
      </div>

      <div className="SeriesHero__video">
        <YoutubeEmbed embedId={video} />
      </div>
    </section>
  );
}

export default SeriesHero;
