import './CartProduct.scss';

function CartProduct({
  id,
  img,
  name,
  price,
  delivery,
  className,
  quant,
  maxQuant,
  handleCart,
}) {
  return (
    <div className={`CartProduct ${className}`}>
      <img src={img} />
      <h5>{name}</h5>
      <p className="CartProduct__price CartProduct__price_delivery">
        $ {price * quant}
      </p>
      {!delivery && (
        <>
          <div className="CartProduct__quantity">
            {quant === 1 ? (
              <button disabled onClick={() => handleCart(id, '-')}>
                &#8722;
              </button>
            ) : (
              <button onClick={() => handleCart(id, '-')}> &#8722; </button>
            )}
            <p>{quant > maxQuant ? maxQuant : quant}</p>
            {quant === maxQuant ? (
              <button disabled onClick={() => handleCart(id, '+')}>
                &#43;
              </button>
            ) : (
              <button onClick={() => handleCart(id, '+')}>&#43;</button>
            )}
          </div>
          <button
            className="CartProduct__close"
            onClick={() => handleCart(id, 'x')}>
            &#x2715;
          </button>
        </>
      )}
      {delivery && (
        <>
          <div className="CartProduct__quantity">
            <p>{quant > maxQuant ? maxQuant : quant}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default CartProduct;
