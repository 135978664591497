import './MerchHero.scss';
import Arrow from '../Arrow/Arrow';
import bg from './img/merchbg.jpg';

function MerchHero({ series, handleCart, epId, cart, episodeId }) {
  const ep = series.find((seria) => {
    return seria.episode === epId;
  });
  const {
    episode,
    merchName,
    merchPrice,
    merchImg,
    merchDesc,
    merchStatus,
    merchQuant,
  } = ep;

  const cartItem = cart.find((item) => item.itemId === episode);
  const itemCount = cartItem ? cartItem.count : 0;

  const currentIndex = series.findIndex((seria) => seria.episode === epId);

  const nextEpisode = () => {
    let nextIndex = (currentIndex + 1) % series.length;
    while (!series[nextIndex].merchName) {
      nextIndex = (nextIndex + 1) % series.length;
    }
    episodeId(series[nextIndex].episode);
  };

  const prevEpisode = () => {
    let prevIndex = (currentIndex - 1 + series.length) % series.length;
    while (!series[prevIndex].merchName) {
      prevIndex = (prevIndex - 1 + series.length) % series.length;
    }
    episodeId(series[prevIndex].episode);
  };

  return (
    <section className="MerchHero">
      <div className="MerchHero__img" style={{ background: `url(${bg})` }}>
        <div className="gradientTop"></div>
        <Arrow
          dirct="left"
          className="Arrow__left Arrow"
          onClick={prevEpisode}
        />
        {merchImg && <img className="img" src={merchImg} />}
        <Arrow
          dirct="right"
          className="Arrow__right Arrow"
          onClick={nextEpisode}
        />
        <div className="gradientBottom"></div>
      </div>

      <div className="MerchHero__info">
        <h1>{merchName}</h1>

        <div className="MerchHero__info__item">
          <h5 className="MerchHero__info__item__status">{merchStatus}</h5>
          <h5 className="MerchHero__info__item__num">
            <span>{episode}</span>episode
          </h5>
          <h5 className="MerchHero__info__item__quantity">
            <span>{merchQuant}</span>items
          </h5>
        </div>

        <p className="MerchHero__info__description">{merchDesc}</p>

        <div className="MerchHero__info__buy">
          {itemCount && itemCount === merchQuant ? (
            <button disabled onClick={() => handleCart(episode, '+')}>
              BUY
            </button>
          ) : (
            <button onClick={() => handleCart(episode, '+')}>BUY</button>
          )}

          <h2>$ {merchPrice}</h2>
        </div>
      </div>
    </section>
  );
}

export default MerchHero;
