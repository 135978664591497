import './Mint.scss';

function Mint() {
  return (
    <div className="Mint">
      <div className="Mint__left">
        <div className="Mint__left__timer">
          <span>00:08:39:23</span> <p>Until auction ends</p>
        </div>
        <div className="Mint__left__price">
          <span>2.88 ETH</span> <p>Current price</p>
        </div>
        <div className="Mint__left__quantity">
          <label for="file">1585 / 8000</label>
          <div className="progress_wrapper">
            <progress value="1585" max="8000"></progress>
          </div>
        </div>
      </div>
      <div className="Mint__right">
        <h1>MINT Tigrilla mode</h1>
        <form>
          <legend>1. Enter the number of tokens to mint.</legend>
          <input type="number" min="1" max="10" step="1" required />
        </form>
        <p>
          2. Click the button to mint 1 Tigrilla for <span>2.88 ETH</span>
        </p>
        <button>CONNECT WALLET</button>
      </div>
    </div>
  );
}

export default Mint;
