import React, { useState, useEffect, useRef } from 'react';
import loader from './img/tgrLogo.gif';

const videos = {
  T1: {
    src: require('./Tigers/video/T1.mp4'),
    cached: false,
  },
  T2: {
    src: require('./Tigers/video/T2.mp4'),
    cached: false,
  },
  T3: {
    src: require('./Tigers/video/T3.mp4'),
    cached: false,
  },
  T4: {
    src: require('./Tigers/video/T4.mp4'),
    cached: false,
  },
  T5: {
    src: require('./Tigers/video/T5.mp4'),
    cached: false,
  },
  T6: {
    src: require('./Tigers/video/T6.mp4'),
    cached: false,
  },
  T7: {
    src: require('./Tigers/video/T7.mp4'),
    cached: false,
  },
  T8: {
    src: require('./Tigers/video/T8.mp4'),
    cached: false,
  },
};

function HeroTigrilla({ tiger }) {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);

  // state variables for animation
  const [animationDuration, setAnimationDuration] = useState(4); // starting duration
  const [scaleFactor, setScaleFactor] = useState(1); // starting scale factor

  useEffect(() => {
    const cacheVideo = async () => {
      if (!videos[tiger].cached) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', videos[tiger].src, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            const blob = xhr.response;
            const url = URL.createObjectURL(blob);
            videos[tiger].cached = true;
            localStorage.setItem(`tiger-${tiger}`, url);
            if (videoRef.current) {
              videoRef.current.src = url;
              videoRef.current.load();
              setLoading(false);
            }
          }
        };
        xhr.send();
      } else {
        const url = localStorage.getItem(`tiger-${tiger}`);
        if (videoRef.current && url) {
          videoRef.current.src = url;
          videoRef.current.load();
          setLoading(false);
        }
      }
    };

    setLoading(true);
    cacheVideo();
  }, [tiger]);

  // update animation duration and scale factor randomly
  useEffect(() => {
    const randomDuration = Math.floor(Math.random() * (6 - 3 + 1)) + 3; // random number between 3 and 6 seconds
    setAnimationDuration(randomDuration);
    const randomScaleFactor = Math.floor(Math.random() * (5 - 2 + 1)) + 2; // random number between 2 and 5
    setScaleFactor(randomScaleFactor);
  }, []);

  return (
    <div className="Hero__tigrilla">
      <div className="gradient">
        <div className="Hero__tigrilla__video">
          <video ref={videoRef} loop autoPlay muted playsInline>
            <source type="video/mp4" />
          </video>
          {loading ? (
            <div
              class="Hero__tigrilla__video__loader"
              style={{ background: `url(${loader})` }}
            ></div>
          ) : (
            <div
              className="Hero__tigrilla__video__autofocus"
              style={{
                animationDuration: `${animationDuration}s`,
                transform: `scale(${scaleFactor})`,
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeroTigrilla;
