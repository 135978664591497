// PlayButton.jsx
import React, { useContext } from 'react';
import AudioContext from './AudioContext';

const PlayButton = ({ handleEnter }) => {
  const { isPlaying, setIsPlaying } = useContext(AudioContext);

  return (
    <button
      onClick={() => {
        setIsPlaying(!isPlaying);
        handleEnter();
      }}>
      Enter
    </button>
  );
};

export default PlayButton;
