import "./Cart.scss";
import CartList from "./CartList";

function Cart({ handleCart, series, cart, cartSumm }) {
  return (
    <section className="Cart">
      <CartList
        handleCart={handleCart}
        series={series}
        cart={cart}
        cartSumm={cartSumm}
      />
    </section>
  );
}

export default Cart;
