import "./Delivery.scss";
import CartProduct from "./CartProduct";

function Delivery({ series, cart, cartSumm }) {
  return (
    <section className="Delivery">
      <div className="Delivery__info">
        <form>
          <label>
            Shipping details:
            <input type="text" placeholder="first name" required />
            <input type="text" placeholder="last name" required />
            <input type="tel" placeholder="phone" required />
            <input type="email" placeholder="email" />
            <input type="country" placeholder="country" required />
            <input type="text" placeholder="city" required />
            <input type="text" placeholder="postcode" required />
            <input type="text" placeholder="address" required />
            <input type="text" placeholder="additional information" />
          </label>
        </form>
        <form className="checkboxes">
          <input
            type="checkbox"
            name="emails"
            className="checkbox"
            id="emails"
            value="yes"
          />
          <label for="emails">Recive emails from us</label>
          <input
            type="checkbox"
            name="terms"
            className="checkbox"
            id="terms"
            value="yes"
            required="required"
          />
          <label for="terms">I’ve read and accept the terms & conditions</label>
        </form>
      </div>
      <div className="Delivery__order">
        <h1>Your order:</h1>
        <div className="Delivery__order__list">
          {cart
            ? cart.map((item) => {
                const ep = series.find((seria) => {
                  return seria.episode === item.itemId;
                });
                return (
                  <CartProduct
                    key={ep.episode}
                    id={ep.episode}
                    img={ep.merchImg}
                    name={ep.merchName}
                    price={ep.merchPrice}
                    className="CartProduct_cart"
                    quant={item.count}
                    maxQuant={ep.merchQuant}
                    delivery
                  />
                );
              })
            : ""}
        </div>
        <div className="Delivery__order__subtotal">
          <h1>Subtotal:</h1>
          <h1>$ {cartSumm}</h1>
        </div>
        <button>Place order</button>
      </div>
    </section>
  );
}

export default Delivery;
