import { useState } from 'react';
import moment from 'moment';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './Card.scss';
import lock from './img/lock.png';

function Card({ num, date, image, episodeId, link }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));
  function calculateTimeLeft(date) {
    const difference = date - Math.floor(Date.now() / 1000);
    const duration = moment.duration(difference * 1000, 'milliseconds');

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }
  function startTimer() {
    setInterval(() => {
      setTimeLeft(calculateTimeLeft(date));
    }, 1000);
  }
  if (date > Math.floor(Date.now() / 1000)) {
    startTimer();
  }

  return date ? (
    date > Math.floor(Date.now() / 1000) ? (
      <div
        className={`Card${link === 'merch' ? ' Card__merch' : ''}`}
        style={{ backgroundImage: `url(${image})` }}>
        <h5>
          <span>{num} </span>episode
        </h5>
        <div className="Card Card__timer">
          <div className="blur"></div>
          <h5>
            <span>{num} </span>episode
          </h5>
          <div className="timer">
            {timeLeft.days > 0 && <span>{timeLeft.days} days </span>}
            <span>{timeLeft.hours}:</span>
            <span>
              {timeLeft.minutes < 10
                ? `0${timeLeft.minutes}`
                : timeLeft.minutes}
              :
            </span>
            <span>
              {timeLeft.seconds < 10
                ? `0${timeLeft.seconds}`
                : timeLeft.seconds}
            </span>
            <span> left</span>
          </div>
        </div>
      </div>
    ) : (
      <Link
        onClick={() => episodeId(num)}
        to={link}
        className={`Card${link === 'merch' ? ' Card__merch' : ''}`}
        style={{ backgroundImage: `url(${image})` }}>
        <h5>
          <span>{num} </span>episode
        </h5>
      </Link>
    )
  ) : (
    <div
      className={` Card Card__lock${link === 'merch' ? ' Card__merch' : ''}`}
      style={{ backgroundImage: `url(${image})` }}>
      <div className="blur"></div>
      <h5>
        <span>{num} </span>episode
      </h5>
      <img src={lock} />
    </div>
  );
}

export default Card;
