import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
import './About.scss';
import merch from './img/merch.jpeg';
import gamification from './img/gamification.jpeg';
import series2 from './img/series/series2.jpeg';
import series3 from './img/series/series3.jpeg';
import series4 from './img/series/series4.jpeg';
import series5 from './img/series/series5.jpeg';
import series6 from './img/series/series6.jpeg';
import series7 from './img/series/series7.jpeg';
import series8 from './img/series/series8.jpeg';
import series9 from './img/series/series9.jpeg';
import series10 from './img/series/series10.jpeg';
import series11 from './img/series/series11.jpeg';
import series12 from './img/series/series12.jpeg';
import series13 from './img/series/series13.jpeg';
import nft1 from './img/nfts/nft1.jpeg';
import nft2 from './img/nfts/nft2.jpeg';
import nft3 from './img/nfts/nft3.jpeg';
import nft4 from './img/nfts/nft4.jpeg';
import nft5 from './img/nfts/nft5.jpeg';
import nft6 from './img/nfts/nft6.jpeg';
import nft7 from './img/nfts/nft7.jpeg';
import nft8 from './img/nfts/nft8.jpeg';

SwiperCore.use([Autoplay]);

function About() {
  return (
    <section className="About">
      <div className="About__welcome">
        <div className="About__welcome__text">
          <h2>Welcome to the tigrilla mode!</h2>
          <p className="welcome">
            Welcome to the Tigrilla Mode! A crypto-project where you can enjoy
            an exciting series and at the same time purchase unique limited
            edition merch.
          </p>
          <p className="welcome">
            You can also interact with other users and play games using NFTs,
            which will allow you to earn and receive additional gaming content.
          </p>
          <p className="welcome">
            Our project is based on innovative ideas and active interaction with
            our users, and we are happy to welcome you to our friendly and
            crypto-oriented environment. Welcome to Tigrilla Mode!
          </p>
        </div>
        <div className="About__welcome__img"></div>
      </div>
      <div className="About__series">
        <div className="About__series__text">
          <h2>Tigrilla mode series</h2>
          <p>
            The series Tigrilla Mode is an exciting story about a hero who left
            the digital world thanks to Designer and his NFT.
          </p>
          <p>
            After creating the NFT, Designer picked up a mysterious flash drive
            on the street, inserted it into his laptop, and the NFT transformed
            into the real hero Tigrilla.
          </p>
          <p>
            With Designer on the line, Tigrilla understands what is happening in
            this world and becomes a hero capable of fighting evil and
            protecting the good.
          </p>
          <p>
            Follow every episode and don't miss out on the activities that the
            developers have in store for you.
          </p>
        </div>
        <div className="About__series__img">
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}>
            <SwiperSlide>
              <img src={series2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series5} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series6} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series7} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series8} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series9} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series10} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series11} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series12} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={series13} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="About__merch">
        <div className="About__merch__img">
          <img src={merch} alt="" />
        </div>
        <div className="About__merch__text">
          <h2>Tigrilla mode merch</h2>
          <p>
            Each episode of Tigrilla Mode series will be accompanied by the
            release of original merchandise that can be purchased with
            multi-tokens in a limited offer.
          </p>
          <p>
            Each merchandise item will have a unique design and relate to a
            specific series.
          </p>
          <p>
            Users who collect all items in the collection will be able to
            participate in a raffle with unique rewards.
          </p>
        </div>
      </div>
      <div className="About__NFT">
        <div className="About__NFT__text">
          <h2>Tigrilla mode nft</h2>
          <p>
            After the completion of the series, a drop of NFT will be announced,
            which can be used in games in the future.
          </p>
          <p>
            NFT owners will have access to exclusive game content and advantages
            in games where this collection is used.
          </p>
          <p>
            The collection will consist of 8888 Tigrillas and more than 100
            unique items, all of which will be manually drawn by Designer.
          </p>
        </div>
        <div className="About__NFT__img">
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}>
            <SwiperSlide>
              <img src={nft1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft5} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft6} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft7} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={nft8} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="About__gamification">
        <div className="About__gamification__img">
          <img src={gamification} alt="" />
        </div>
        <div className="About__gamification__text">
          <h2>Tigrilla mode gamification</h2>
          <p>
            Tigrilla Mode is not only a series but also a game where users can
            interact with each other and complete various gaming tasks.
          </p>
          <p>
            NFT will be used in the game, allowing players to unlock additional
            game content and opportunities.
          </p>
          <p>
            The future of the project will be based on active user interaction,
            using DAO and innovative ideas.
          </p>
        </div>
      </div>
      {/* <div className="About__great">
        <h2>let’s make animals great again!</h2>
        <p>Tigrilla mode metaverse based on win2win principles.</p>
        <p>Become our adopter and get rewards for making our world better.</p>
        <p>
          Become our partner and fill a charity and rewards pool for animal
          lovers' audience activity.
        </p>
      </div> */}
    </section>
  );
}

export default About;
