import './MerchGallery.scss';
import MerchCard from './MerchCard';

function MerchCallery({ series, link, episodeId }) {
  return (
    <div className="MerchGallery">
      {series.map((seria) => (
        <MerchCard
          key={seria.episode}
          num={seria.episode}
          image={seria.merchImg}
          title={seria.merchName}
          description={seria.merchDesc}
          status={seria.merchStatus}
          quant={seria.merchQuant}
          price={seria.merchPrice}
          episodeId={episodeId}
          link={link}
        />
      ))}
    </div>
  );
}

export default MerchCallery;
